<template>
  <div class="case-detail-container">
    <header>
      <a class="back" @click="back">
        <i class="icon-leftarrow"></i>
        返回
      </a>
      <router-link
        class="chatroom-history"
        :to="{ name: 'videoClinic', params: { roomNumber } }"
        v-if="status > 5 && status < 20 && roomNumber"
        v-slot="{ href }"
      >
        <a :href="href">聊天室對話記錄</a>
      </router-link>
    </header>
    <doctor-info :doctorData="doctorData"></doctor-info>
    <router-view></router-view>
  </div>
</template>

<script>
import DoctorInfo from "../../components/doctorInfo.vue";
import { casesDetail } from "@/api";
import { mapMutations } from "vuex";
export default {
  components: {
    DoctorInfo,
  },
  data: function () {
    return {
      doctorData: {
        avatar: "",
        name: "",
        gender: "",
        specialist: "",
      },
      roomNumber: 0,
      status: 0,
    };
  },
  methods: {
    ...mapMutations({
      setCaseData: "caseDetail/setCaseData",
      setBillData: "caseDetail/setBillData",
    }),
    back() {
      this.$router.go(-1);
    },
  },
  mounted: function () {
    let caseId = this.$route.params.id;
    casesDetail(caseId).then((res) => {
      // console.log(res);
      let data = this.$camel(res.data);
      this.doctorData.avatar = data.doctor.avatar;
      this.doctorData.name = data.doctor.name;
      this.doctorData.gender = data.doctor.gender;
      this.doctorData.specialist = data.doctor.specialist;
      if (data.chatroom) this.roomNumber = data.chatroom.roomNumber;
      this.status = data.status;
      let type = data.typeId == 1 ? "video" : "text";
      data.type = type;
      if (type === "video") {
        let { date, time } = this.$time.appointmentToSeperatedDate(
          data.appointmentDate,
          data.appointmentTime
        );
        data.appointmentDate = date;
        data.appointmentTime = time;
      } else {
        let { date, time } = this.$time.createdAtToSeperatedDate(
          data.createdAt
        );
        data.appointmentDate = date;
        data.appointmentTime = time;
      }
      let dateList = {
        0: data.createdAt,
        5: data.statusBookingAt,
        10: data.statusSuccessAppointmentAt,
        15: data.statusProcessingAt,
        20: data.statusWaitingReportAt,
        25: data.statusWaitingPaymentAt,
        30: data.statusWaitingDeliveryAt,
        33: data.statusRefundingAt,
        35: data.statusDeliveryAt,
        40: data.statusCompletedAt,
      };
      data.dateList = dateList;
      data.orderList.forEach((e) => {
        if (e.typeId == 2) {
          this.setBillData(e);
        }
      });
      this.setCaseData(data);
    });
  },
};
</script>

<style lang="scss" scoped>
.case-detail-container {
  border-top: var(--safe-top) solid $green-300;
  padding: 0 0 var(--safe-bottom);
  background-color: $white;
  @include flexc-start-stretch;
  min-height: 100vh;
}
header {
  width: 100%;
  padding: 32px 16px 0 16px;
  @include flexr-between-center;
  color: white;
  background-color: $green-300;
  .back {
    color: white;
    font-weight: $semi-bold;
    font-size: 24px;
    text-decoration: none;
  }
  .chatroom-history {
    color: white;
    font-weight: $regular;
    font-size: 16px;
    text-decoration: underline;
  }
}
</style>